/* ----------------------------------------------------------------------------
	Typography
----------------------------------------------------------------------------- */
h1, h2, h3, h4, h5 {
	/*margin: 25px 0 15px 0;*/
	margin: 1.2em 0 0.5em 0;
	padding: 0;
	color: #444;
	font-family: 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
	font-weight: 600 !important;
	text-rendering: optimizeLegibility;
	line-height: 1.4;
}
h1, .h1 { font-size: 1.602em; margin-top: 0; }
h2, .h2 { font-size: 1.424em; }
h3, .h3 { font-size: 1.266em; }
h4, .h4 { font-size: 1.125em; }
h5, .h5 { font-size: 1em; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: #1982d1; }
p { margin: 0 0 1.3em 0; }
a { color: #1982d1; text-decoration: none; outline: none !important; }
a:hover, a:active { /*text-decoration: underline;*/ border-width: 0 0 1px 0; border-style: dotted; -webkit-tap-highlight-color: rgba(0,0,0,0); }
a.img:hover, a.img:active { text-decoration: none; border: 0; }
ul, ol { margin-left: 35px; margin-top: 1em; margin-bottom: 1em; }
a[href^="tel:"] { color: inherit; border: 0; }
ul.nav, ol.nav { margin-left: 0; margin-top: 1em; margin-bottom: 1em; }
h1 + ul, h2 + ul, h3 + ul, h4 + ul, h1 + ol, h2 + ol, h3 + ol, h4 + ol  { margin-top: 0; }
ul { list-style: disc outside; }
ol { list-style: decimal; }
ul.check, ul.star, ul.carrot { margin-left: 15px; list-style: none outside; }
ul.disc { list-style: disc outside; }
ul.circle { list-style: circle outside; }
ul.square { list-style: square outside; }
ul.check li, ul.star li, ul.carrot li { padding: 0 0 0 22px; }
ul.carrot li { padding: 0 0 0 16px; }
ul.check > li:before, ul.star > li:before, ul.carrot > li:before { display: inline-block; margin-top: 1px; margin-left: -22px; width: 22px; content: "\e840"; height: inherit; color: $secondary-color; font-family: 'icons'; font-size: 14px; vertical-align: top; font-weight: normal !important; }
ul.star > li:before { content: "\e834"; }
ul.carrot > li:before { margin-top: -1px; margin-left: -16px; width: 16px; content: "\00BB"; color: inherit; font-size: inherit; }
ul.check li li, ul.star li li, ul.carrot li li { padding: 0; }
ul ul, ul ol, ol ol, ol ul { margin: 5px 0 5px 25px; }
ol ol { list-style: lower-alpha; }
ol ul, ul ul { list-style: circle; }
ul ol { list-style: decimal; }
ul li, ol li { margin: 5px 0 5px 0; }
dl dt { font-weight: bold; }
#content, #main_content, .break_word { overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; }

@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
    h1, .h1 { font-size: 1.8em; }
	h2, .h2 { font-size: 1.55em; }
	h3, .h3 { font-size: 1.32em; }
	h4, .h4 { font-size: 1.125em; }
	h5, .h5 { font-size: 1em; }
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) */
	h1, .h1 { font-size: 2.074em; }
	h2, .h2 { font-size: 1.728em; }
	h3, .h3 { font-size: 1.44em; }
	h4, .h4 { font-size: 1.2em; }
	h5, .h5 { font-size: 1.1em; }
}
@media (min-width: 75em) { /* Extra large devices (large desktops, 1200px and up) */
	h1, .h1 { font-size: 2.441em; }
	h2, .h2 { font-size: 1.953em; }
	h3, .h3 { font-size: 1.563em; }
	h4, .h4 { font-size: 1.25em; }
	h5, .h5 { font-size: 1.1em; }
}
/* ----------------------------------------------------------------------------
	Forms Elements
----------------------------------------------------------------------------- */
form { margin: 0 0 20px 0; width: 100%; }
label { display: inline-block; line-height: 1.625em; cursor: pointer; margin-bottom: 2px; padding-left: 3px; width: auto; vertical-align: middle; -webkit-tap-highlight-color: rgba(0,0,0,0); }
textarea { height: 150px }
fieldset { border-style: solid; border-width: 0.0625em; padding: 1.5625em; border-color: #d8d8d8; margin: 18px 0; }
fieldset legend { padding: 5px 10px }
.form_row { clear: both; position: relative; max-width: 100%; margin-bottom: 10px; vertical-align: middle; overflow: visible; }
input, input[type="*"], textarea, select, .select { display: inline-block; max-width: 100%; width: 100%; padding: 0; margin: 0; border: none; box-shadow: none; overflow: visible; vertical-align: middle; -webkit-tap-highlight-color: rgba(0,0,0,0); }
input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], input[type="search"], textarea { -webkit-appearance: none; -moz-appearance: none; appearance: none; position: relative; padding: 0 10px; background: #fff; border: 1px solid #cccccc; height: 40px; line-height: 36px; border-radius: 4px; transition: border 0.3s ease 0s, box-shadow 0.3s ease 0s, background 0.3s ease 0s;  }
/*input[type="search"] { height: 40px; line-height: 36px; border-radius: 1000px; }*/
textarea { height: 115px; min-height: 115px; padding: 5px 0 0 10px; line-height: 1.6em; resize: vertical; }
input:focus, textarea:focus, select:focus { background-color: #ffffff; outline: 0; border: 1px solid #a1c4e5; box-shadow: 0 0 3px rgba(43, 153, 255, 1); box-shadow: 0 0 3px rgba(43, 153, 255, 0.5); transition: all 0.3s ease 0s; }
/* this next line is here becasue of IE9 not supporting :invalid - duplicate rules with added selectors */
input:focus, textarea:focus, select:focus, input:invalid:focus, input.invalid:focus { background-color: #ffffff; outline: 0; border: 1px solid #a1c4e5; box-shadow: 0 0 3px rgba(43, 153, 255, 1); box-shadow: 0 0 3px rgba(43, 153, 255, 0.5); transition: all 0.3s ease 0s; }
input.invalid { background: #fffef4; border-color: #f0ad4e; border-style: dashed; }
/* this next line is here becasue of IE9 not supporting :invalid - duplicate rules with added selectors */
input:invalid, input.invalid { background: #fffef4; border-color: #f0ad4e; border-style: dashed; }
button:focus { outline:0; }
.select select { width: 100% !important; }
select { padding: 6px 30px 6px 6px; width: 100%; height: 40px; border: none; background: #ffffff url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojNDQ0NDQ0O30KCS5zdDF7ZmlsbDpub25lO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTcuNCw5LjNsNC42LDQuNmw0LjYtNC42bDEuNCwxLjRsLTYsNmwtNi02TDcuNCw5LjN6Ii8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0wLTAuOGgyNHYyNEgwVi0wLjh6Ii8+Cjwvc3ZnPg==) no-repeat; background-position: right 0.35em center; background-size: 20px 20px; outline: 0; -webkit-appearance: none; -moz-appearance: none; appearance: none; text-indent: 0; text-overflow: ''; z-index: 99; cursor: pointer; transition: none !important; border: 1px solid #d8d8d8; border-radius: 3px; }
.ie9 select { padding-right: 5px; background-image: none; }
select[multiple] { padding: 4px 0; height: auto; text-indent: 1px; background-image: none; overflow-y: scroll; }
/*.touch select[multiple] { min-height: 38px; height: 38px; padding: 6px 0 6px 7px; }*/
select::-ms-expand { display: none }
select[multiple] option { padding: 2px 10px; width: 100%; }
select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #555555; }
label.spacer { display: none; }
label.radio, label.checkbox { display: inline-block; margin: 4px 15px 4px 0; margin-left: 5px !important; padding-left: 26px !important; width: auto !important; text-align: left !important; vertical-align: middle; position: relative; }
label.radio.block, label.checkbox.block { margin: 4px; width: 100% !important; }
label.radio input[type="radio"], label.checkbox input[type="checkbox"] { display: inline-block; width: 1px; height: 1px; position: absolute; opacity: 0; z-index: -1; top: 3px; left: 3px; }
input[type="radio"] + label, input[type="checkbox"] + label { display: inline-block; margin-left: 3px; padding-left: 0; text-align: left; width: auto; }
input[type="radio"], input[type="checkbox"] { display: inline-block; width: auto; }
input[type="radio"]:focus, input[type="checkbox"]:focus { box-shadow: none; outline: 0; border: 0; }
input[type="radio"]~span:before, input[type="checkbox"]~span:before { display: block; width: 20px; height: 20px; position: absolute; left: 0; top: 2px; border: 2px solid #ccc; content:''; border-radius: 3px; background: #fff; background-size: 5px 5px; background-position: center center; transition: all 0.2s ease 0s; }
input[type="radio"]~span:before { border-radius: 50px; box-shadow: inset 0 0 0 6px #ffffff; }
input[type="radio"]:checked~span:before { border: 2px solid #2B99FF; background: #2B99FF; box-shadow: inset 0 0 0 3px #fff; transition: all 0.2s ease 0s; }
input[type="checkbox"]:checked~span:before { border: 2px solid #2B99FF; background-color: #ffffff; background-position: center center; background-size: 14px 14px; background-repeat:no-repeat; background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMkI5OUZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTE5LjEsMy4zbC05LjcsMTBMNC45LDkuMmwtMy43LDMuN2w4LjEsNy44TDIyLjgsN0wxOS4xLDMuM3oiLz4KPC9zdmc+); transition: all 0.2s ease 0s; }
input[type="submit"], input[type="button"], button { display: inline-block; padding: 0 15px; background: #f7f7f7; height: 40px; line-height: 34px; border-radius: 4px; width: 100%; max-width: 220px; font-size: 100%; cursor: pointer; font-weight: 400; text-shadow: 0 1px 1px #fff; background-image: linear-gradient(#f7f7f7, #ebebeb); box-shadow: inset 0 0 3px white; border: 1px solid #cccccc; position: relative; vertical-align: middle; }
input[type="submit"]:hover, input[type="button"]:hover, button:hover { background-image: linear-gradient(#ebebeb, #f7f7f7); }
input[type="submit"]:active, input[type="button"]:active, button:active { background: #f7f7f7; border: 1px solid #cccccc; line-height: 40px; overflow: hidden;  }
.form_row.block { margin-bottom: 10px; }
.form_row.block label { display: block; padding-left: 3px; width: 100%; text-align: left; }
.form_row.block input, .form_row.block input[type="*"], .form_row.block textarea, .form_row.block select { display: block; width: 100%; }
.form_row.block input[type="submit"], .form_row.block input[type="button"], .form_row.block button { display: block; margin-left: auto; margin-right: auto; }
.form_row.block.textarea label, .form_row.block.multiple_select label { margin-top: 0 }
.form_row .field_group { display: inline-block; vertical-align: middle; width: 100%; }
.form_row .field_group input { display: inline-block !important; vertical-align: middle; margin-left: 3px; }
.form_row .field_group input:first-child { margin-left: 0; }
.form_row .field_group label.radio, .form_row .field_group label.checkbox { width: auto !important; margin-left: 5px; margin-right: 10px; }
.field_group { display: inline-block; }
.field_group input[maxlength="2"] { max-width: 65px; }
.field_group input[maxlength="3"] { max-width: 75px; }
.field_group input[maxlength="4"] { max-width: 85px; }
.helper { color: #999999; }
@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
	label.radio, label.checkbox { padding-right: 0 !important; }
	input, input[type="*"], textarea, select, .form_row .field_group, .select { width: 65%; }
	label.spacer { display: inline-block !important; height: 10px; line-height: 10px; }
	.form_row { margin-bottom: 15px; }
	.form_row label { display: inline-block; padding-left: 0; padding-right: 5px; width: 30%; text-align: right; }
	.form_row.textarea label, .form_row.multiple_select label { margin-top: 4px !important; vertical-align: top; }
	.form_row.block .select { width: 100%; }
	/*.touch .form_row.multiple_select label { margin-top: 0 !important; vertical-align: middle; }*/
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
.btn { display: inline-block; margin: 10px; padding: 10px 0 7px 0; color: #fff; background: #555555; text-align: center; position: relative; width: 100%; max-width: 220px; /*height: 45px;*/ line-height: 1.35; font-size: 21px; font-weight: normal; font-family: 'Fjalla One', Impact, Verdana; text-transform: uppercase; letter-spacing: 0.01em; border-radius: 4px; cursor: pointer; /*text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);*/ vertical-align: middle; outline: 0; overflow: hidden; /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);*/ transition: all 0.2s ease 0s; }
.btn.fancy { /*line-height: 46px; box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.8); border: 1px solid #cccccc;*/ background: #f7f7f7; background-image: linear-gradient(#f7f7f7, #ebebeb); transition: none;  }
.btn:hover, .btn:active { text-decoration: none; -webkit-tap-highlight-color: rgba(0,0,0,0); background: #464D54; border: 0; }
.btn.fancy:hover, .btn.fancy:active { /*border-width: 1px; border-style: solid;*/ }
.btn.big { padding: 13px 0 10px 0; }
.btn.big.fancy { /*line-height: 56px;*/ }
.btn.big.outline { padding: 12px 0 9px 0; }
.btn.small { padding: 9px 0 6px 0; font-size: 18px; }
.btn.small.fancy { line-height: 41px; }
.btn.small.outline { padding: 9px 0 5px 0; }
.btn.dark { color: #fff; background: #555555; }
.btn.orange { color: #fff; background: #FF6633; }
.btn.yellow { color: #333; background: #ffc107; }
.btn.green { color: #fff; background: #5CB865; }
.btn.red { color: #fff; background: #f44336; }
.btn.blue { color: #fff; background: #0099ff; }
.btn[class~=outline] { border: 1px solid #555555; }
.btn.outline { color: #555555; background: transparent; border-color: #555555; }
.btn.dark.outline { color: #555555; background: transparent; border-color: #555555; }
.btn.orange.outline { color: #FF6633; background: transparent; border-color: #FF6633; }
.btn.green.outline { color: #5CB865; background: transparent; border-color: #5CB865; }
.btn.red.outline { color: #f44336; background: transparent; border-color: #f44336; }
.btn.yellow.outline { color: #ffc107; background: transparent; border-color: #ffc107; }
.btn.blue.outline { color: #0099ff; background: transparent; border-color: #0099ff; }
.btn.pill { border-radius: 100px; }
.btn.dark.fancy { text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5); background: #555555; background-image: linear-gradient(#7B8085, #464D54);  }
.btn.dark:hover, .btn.dark:active { background: #464D54; }
.btn.outline:hover, .btn.outline:active, .btn.dark.outline:hover, .btn.dark.outline:active { background: rgba(85, 85, 85, 0.1); }
.btn.orange.fancy { text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); background: #FF6633; background-image: linear-gradient(#ff9933, #ff6633); }
.btn.orange:hover, .btn.orange:active { background: #fb8c00; }
.btn.orange.outline:hover, .btn.orange.outline:active { background: rgba(255, 102, 51, 0.1); }
.btn.green.fancy { text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); background: #5CB865; background-image: linear-gradient(#62C462, #51A351); color: #fff; }
.btn.green:hover, .btn.green:active { background: #55ab5d; }
.btn.green.outline:hover, .btn.green.outline:active { background: rgba(92, 184, 101, 0.1); }
.btn.red.fancy { text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); background: #f44336; background-image: linear-gradient(#ff6633, #ff0000); }
.btn.red:hover, .btn.red:active { background: #ff5252; }
.btn.red.outline:hover, .btn.red.outline:active { background: rgba(244, 67, 54, 0.1); }
.btn.blue.fancy { text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3); background: #3daab3; background-image: linear-gradient(#52dce7, #3daab3); }
.btn.blue:hover, .btn.blue:active { background: #3daab3; }
.btn.blue.outline:hover, .btn.blue.outline:active { background: rgba(0, 153, 255, 0.1); }
.btn.yellow.fancy { text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5); background: #ffcc00; background-image: linear-gradient(#ffe300, #ff9900); }
.btn.yellow:hover, .btn.yellow:active { background: #ffd54f; }
.btn.yellow.outline:hover, .btn.yellow.outline:active { background: rgba(255, 193, 7, 0.1); }
.btn.disabled{
	background: #999 !important;
	pointer-events: none;
	color: #fff;
	text-shadow: none;
}


/* ----------------------------------------------------------------------------
	Tables
----------------------------------------------------------------------------- */
.table_wrap { margin: 20px 0; width: 100%; overflow-x: auto; }
table { margin: 0 0 10px 0; width: 100%; background: #fff; border-collapse: collapse; border-spacing: 0; text-align: left; }
table th { font-size: inherit; font-weight: bold; padding: 8px 15px; text-align: inherit; border-bottom: 1px solid #999; vertical-align: middle; }
table td { border-top: 1px dotted #ccc; border-bottom: 1px dotted #ccc; padding: 6px 15px; text-align: inherit; vertical-align: middle; }
table th + tr:first-child td { border-top: 0; }
table tr:hover td, table tr:hover th { color: #333; }
table.rounded { border-collapse: separate; border: 1px solid #ccc; border-radius: 4px; }
table.rounded tr:first-child td { border-top: 0; }
table.rounded tr td { border-bottom: 0; }
table.backed { background: #EDEDED; border: 0;}
table.backed th { background: #e4e4e4; border-bottom: 2px solid #fff; }
table.backed th:first-child { border-radius: 4px 0 0 0; }
table.backed th:last-child { border-radius: 0 4px 0 0; }
table.backed td { border-top: 1px solid #fff; }
table.backed th:first-child td { border-top: 0; }
table.backed td { border-bottom: 0; }
table.bordered {  border-collapse: collapse;}
table.bordered th, table.bordered td { border: 1px dotted #ccc; }
table.bordered th { border-bottom: 2px solid #ccc; }

/* ----------------------------------------------------------------------------
	Dropdown List
----------------------------------------------------------------------------- */
.dropdown_list { margin: 15px auto; width: auto; position: relative; box-sizing: border-box; }
.dropdown_list li { margin: 0; padding: 0; list-style: none; }
.dropdown_list span.dropdown { display: block; margin: 0; padding: 0 10px; width: auto; height: 40px; line-height: 38px; background: #fff; border-radius: 3px; border: 1px solid #a1c4e5; cursor: pointer; overflow: hidden; }
.dropdown_list span.dropdown.active { border-radius: 3px 3px 0 0; }
.dropdown_list span.dropdown:hover, .dropdown_list span.dropdown:active { background: #f1f1f1; -webkit-tap-highlight-color: rgba(0,0,0,0); }
.dropdown_list span.dropdown:after { float: right; margin-top: 1px; margin-left: 10px; width: 15px; height: inherit; content: "\e817"; color: ineherit; font-family: 'icons'; text-align: center; font-size: inherit; vertical-align: top; }
.dropdown_list span.dropdown.active:after { content: "\e816" }
.dropdown_list a:hover { text-decoration: none; border-bottom: 0; -webkit-tap-highlight-color: rgba(0,0,0,0); }
.dropdown_list .drop { display: none; float: left; margin: 0 0 0 0; width: 100%; max-height: 300px; position: absolute; left: -999em; /* Hides the drop down */ background: #fff; top: 39px; border-radius: 0 0 3px 3px; z-index: 10; border: 1px solid #a1c4e5; overflow-y: auto; }
.dropdown_list .drop li { float: none; display: block; margin: 0; padding: 0; border: 0; height: auto; border-top: 1px dotted #ccc; }
.dropdown_list .drop li:first-child { border-top: 0 }
.dropdown_list .drop li a { float: none; display: block; margin: 0; padding: 6px 10px 6px 25px; height: auto; width: auto; text-transform: none; color: inherit; background: #fff; text-align: left; border-radius: 0; border: 0; }
.dropdown_list .drop li a:hover, .dropdown_list .drop li a:active { background: #f1f1f1; -webkit-tap-highlight-color: rgba(0,0,0,0); }
.dropdown_list .drop li a:before { display: inline-block; margin-left: -15px; margin-right: 5px; width: 10px; height: inherit; content: "\00BB"; color: inherit; font-family: 'icons'; text-align: center; font-size: inherit; vertical-align: top; }
