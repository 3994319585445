/* --------------------------------------------------------------
	Import CCS Dependencies
-------------------------------------------------------------- */
@import 'variables';
@import 'normalize';
@import 'grid';
@import 'icons';
@import 'default';
@import 'nav';

/* --------------------------------------------------------------
	Start Project CSS
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
	ASC Default stuff
----------------------------------------------------------------------------- */
.price {
    display: inline-block;
    margin: 10px;
    padding: 0;
    width: auto;
    height: auto;
    text-align: center;
    font: bold 0 'Trebuchet MS', 'Droid Serif', Arial, sans-serif;
    overflow: visible;
    vertical-align: middle;
    text-shadow: 0 0 40px #fff;
    color: rgba(0, 0, 0, 0.5);
    transition: .1s;
}

@keyframes toggleTextOpacity {
    0%, 50% {color: rgba(0, 0, 0, 0); }
    100% { color: rgba(0, 0, 0, 1); }
}

.price.shown {
    text-shadow: none;
    animation: toggleTextOpacity .1s linear;
	color: #555;    
	text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}
.price.burst {
    padding: 60px 0 0 0;
    width: 170px;
    height: 170px;
    background: url(../images/price_bg_burst.png) no-repeat 50% 50%;
    text-shadow: 0 0 25px #555;
    color: transparent;
    &.shown{
        color: #555;
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }
}
.price.ribbon{
    background: url(../images/price_bg.png) no-repeat 0 0;
	margin-top: -6px;
	margin-bottom: 0;
	padding: 28px 0 0 0;
	width: 140px;
	height: 93px;
	color: #333;
    text-shadow: 0 0 25px #555;
    &.shown{
        color: #555;
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }
}
.price span.dollar_sign, .price span.cents {
	display: inline-block;
	margin: 5px 0 0 0;
	font-size: 30px;
	line-height: 30px;
	vertical-align: top;
}
.price span.dollars {
	display: inline-block;
	margin-left: -1px;
	font-size: 50px;
	line-height: 50px;
	letter-spacing: -1px;
	vertical-align: top;
}
.price span.cents {
	margin: 4px 0 0 3px;
	letter-spacing: -1px;
}
.action {
	float: none;
	margin: 20px auto;
	padding: 0 0 5px 0;
	max-width: 450px;
	height: 75px;
	background: #fff;
	border: 1px solid #37474f;
	border-radius: 4px;
	text-align: center;
}
.action .price.ribbon {
	vertical-align: top;
}
.action .btn {
	margin-top: 14px;
	max-width: 200px;
	vertical-align: middle;
}
/* --------- asc styles --------- */
html,body {
	background: #f8f8f8;
}
#header a.logo {
	float: left;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	width: 80%;
	max-width: 650px;
}
#header a.logo img {
	display: none;
	float: left;
	max-width: 100%;
	margin-right: 15px;
}
#header a.logo span.big, #header a.logo span.small {
	display: inline;
	margin-top: 4px;
	font-size: 1.5em;
	font-weight: 600;
	color: $main-color;
	line-height: 1em;
	vertical-align: middle;
}
#header a.logo span.small {
	display: none;
	margin-top: 5px;
	font-size: .9em;
	line-height: 1.4em;
	color: #555;
}
#header #support {
	display: none;
	float: right;
	margin: 0 0 0 0;
	width: auto;
}
#header #support p {
	float: right;
	margin: 2px 12px 0 7px;
}
#header #support p strong {
	font-size: 16px;
}
#header #support a.livechat {
	float: right;
	margin: 0 0 0 0;
	padding: 0 12px 2px 12px;
	font-weight: normal;
	color: #fff;
	background: $main-color;
	text-decoration: none;
	border-radius: 0 0 4px 4px;
	border: 0;
}
#header #support a.livechat:hover {
	background: #036;
}
#header #support span.phone {
	display: none;
}
#header a.livechat:before {
	display: inline-block;
	margin-right: 5px;
	width: inherit;
	height: inherit;
	content: "\e830";
	color: inherit;
	font-family: 'icons';
	font-weight: normal;
	text-align: center;
	font-size: 105%;
	vertical-align: top;
}
#footer {
	padding: 15px 0 10px 0;
	color: #999;
	border-top: 1px dashed #ccc;
	overflow: hidden;
}
#footer p {
	margin: 0 0 5px 0;
}
#footer p.trustmark {
	display: none;
	margin: 0;
}
#footer a {
	color: #999;
}
#footer .social a {
	border: 0;
}
#footer img {
	display: inline-block;
}
#footer #trustwaveSealImage {
	float: left;
	margin: 0 10px 30px 10px;
}
#footer img.bbb {
	float: right;
	margin: 0 10px 30px 10px;
}
/* ----------------------------------------------------------------------------
	Start Page Styles
----------------------------------------------------------------------------- */
#header_wrap {
	background: #fff;
}

/* BANNER */
#banner_wrap { 
    background: #e7e7e7 url(../images/banner-bg.jpg) repeat-x; 
    background-size: cover;
	border-bottom: 1px solid #ccc;
}
#banner {
    position: relative;
    background: none;
	img.seal {
		position: absolute;
		top: 10px;
		left: 10px;
		max-width: 95px;
		display: none;
	}
	h2.h1 {
		margin-bottom: 10px;
		color: #333;
		text-transform: uppercase;
		font-weight: 600;
	}
	.details {
		float: none;
		margin: 10px auto 0;
		text-align: center;
		padding: 10px 0;
		ul.check {
			display: inline-block;
			font-size: 1.2em;
			font-weight: 600;
			color: #484f5a;
			text-align: left;
			vertical-align: middle;
		}
		ul.check>li:before { 
			color: $secondary-color; 
			margin-top: 7px; 
		}
		img.guarantee {
			display: none;
			margin: 0px auto 20px;
			vertical-align: middle;
		}
		.button.center { margin-bottom: 20px; }
	}
}

/* POINTS WRAP */
#points_wrap {
	padding-top: 20px;
	overflow: hidden;
    background: #f8f8f8;
	border-bottom: 1px solid #ccc;
}
#points p {
    font-size: 1.1em;
    line-height: 1.4em;
    text-align: center;
    margin-bottom: 0;
}
#points .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
#points .text {
    margin: 5px auto;
    display: block;
    text-align: center;
    font-size: 1.2em;
}
#points .small {
    font-size: 14px;
    margin-bottom: 20px;
	display: none;
}
#points{
	h2 {
		margin-top: 0;
		margin-bottom: 20px;
	}
    h3{
        margin-top: 0;
        text-align: left;
        &:before{
            display: inline-block;
            margin: 0 10px;
            width: 40px;
            height: 40px;
            content: "";
            background: transparent url(../images/steps-icon.png) no-repeat 0 0/cover;
            vertical-align: middle;
            -webkit-transition: opacity 0.25s ease-out 0.25s;
            transition: opacity 0.25s ease-out 0.25s;
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
            margin-bottom: 7px;
        }
        &.step-2:before{
            background-position: -40px 0;
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
        }
        &.step-3:before{
            background-position: -80px 0;
            -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
        }
    }
}
#points a { color: #555; }

.read_more{
    margin-top: 25px;
}

/* CONTENT */
#content_wrap { background: #fff; }
#content {
	background: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
}
p.callout {
    background: #f6f6f6;
    border: 1px solid #93caff;
    border-radius: 3px;
    padding: 10px;
}
p+.accord { margin-top: 20px; }
.accord {
    padding: 7px 10px;
    border-top: 1px dotted #ccc;
}
.accord:last-child { border-bottom: 1px dotted #ccc; }
.accord h2 {
    margin-left: 20px;
    padding-right: 80px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    margin-top: 7px; 
    margin-bottom: 7px;
}
.accord h2:hover,.accord h2:active,.accord h2:focus { color: $secondary-color; }
.accord h2:before {
    color: $secondary-color;
    content: "\e842";
    display: inline-block;
    font-family: "icons";
    font-size: 12px;
    font-weight: normal!important;
    height: inherit;
    margin-left: -18px;
    position: absolute;
    top: 9px;
    width: 18px;
}
.accord h2.active:before { content: "\e843" }

.accord .accord_content {
    margin-top: 10px;
    display: none
}
ul.none {
    list-style-type: none;
    margin-left: 5px;
}

/* ASIDE */
#aside ul.accordion {
	margin-left: 0;
	margin-bottom: 0;
}
#aside li.accordion a {
	font-weight: 600;
	color: #444;
}
#aside li.accordion p {
	display: none;
}
.section {
	margin-top: 15px;
	padding: 15px 20px 10px 20px;
    background: #f7fbfe;
    border: 1px solid #c5e2f0;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
p.social .social {
	display: inline-block;
	margin: 5px;
	vertical-align: middle;
}
#aside p.center {
	margin-top: 15px;
}
#aside p.center img {
	vertical-align: middle;
}
#aside #frmlogin {
	margin-bottom: 5px;
}
#aside #frmlogin #submit {
	margin-top: 15px;
	margin-left: 0;
	width: 150px !important;
}
#aside h4 {
	padding-bottom: 10px;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: -5px;
	margin-right: -5px;
	text-align: center;
	font-weight: 600;
	border-bottom: 1px dotted #d2bfa9;
}
#aside h5 {
	font-weight: 600;
}
#aside h5 small.date {
	margin-left: 5px;
	font-weight: 400;
	color: #777;
	font-style: italic;
}

/* FOOTER */
#footer_wrap {
	background: #f8f8f8;
    border-top: 1px solid #ccc;
}
#footer {
	padding: 0px 15px 0 15px;
	color: #7e7e7e;
	border: none;
	font-size: 0.85em;
}
#footer .widgetized {
	margin-bottom: 30px;
}
#footer h4 {
	margin-top: 0;
}
#footer ul {
	margin: 0;
	list-style: none;
}
#footer li a {
	color: #999;
}
#footer #copyright {
	padding-top: 20px;
	padding-bottom: 20px;
    border: none;
}
/* ----- Login Forms ----- */
.form_wrapper {
	margin-top: 30px;
	padding: 20px;
	max-width: 480px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #ccc;
}
.form_wrapper form {
	margin-bottom: 0;
}
.form_wrapper .signin, .form_wrapper .submit {
	max-width: 200px;
}
.form_wrapper .signin, .form_wrapper .submit {
	margin: 15px 0 0 0 !important;
	max-width: 200px;
}

/* ----------------------------------------------------------------------------
	Page Media Queries
----------------------------------------------------------------------------- */
/*
$breakpoints:
xs: 0,
s: 34.375em,
m: 45em,
l: 60em,
xl: 72em
*/
@media (min-width: 34.375em) { /* Small devices (landscape phones, 549px and up) - col-sm-* */
    #points_wrap { display: block; }
    #points_wrap h3.step-2:before{ background-position: -60px 0; }
	#points_wrap h3:before{ display: block; margin: 0 auto 10px auto; width: 60px; height: 60px; }
    #points_wrap h3.step-2:before{ background-position: -60px 0; }
    #points_wrap h3.step-3:before{ background-position: -120px 0; }
	#points h3 { text-align: center; }
	#points .small { display: block; }
}
@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) - col-md-* */
	#header a.logo span.big { display: inline-block; font-size: 2em; }
	#header a.logo span.small { display: inline-block; }
    .accord h2:after {
		color: $secondary-color;
		content: "+ More Info";
		display: block;
		font-family: "icons";
		font-size: 14px;
		font-weight: normal!important;
		font-family: 'Open Sans','Trebuchet MS',Verdana,Arial,Helvetica;
		height: inherit;
		position: absolute;
		top: 6px;
		right: 0;
    }
    .accord h2.active:after { content: "- Less Info"; }
    #banner .details img.guarantee { display: inline-block; margin-left: 25px; max-width: 135px; }
}
@media only screen and (min-width: 56.25em) { /* roughtly 900px/16px */
    #footer p.trustmark { display: block; }
	#header a.logo img { display: block; }
    #banner h3 { margin-left: 0; }
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) - col-lg-* */
	html { border-top: 3px solid $main-color; }
	#header a.logo { width: 75%; }
	#header #support { display: block; }
    #banner_wrap { background-size: contain; }
	#banner { background: url(../images/banner-person.png) no-repeat; background-position: 10px bottom; }
    #banner .details { text-align: left; padding-left: 250px; }
    #points_wrap h3:before{ width: 120px; height: 120px; }
    #points_wrap h3.step-2:before{ background-position: -120px 0; }
    #points_wrap h3.step-3:before{ background-position: -240px 0; }
}
@media (min-width: 68.75em) { /* Large devices (desktops, 1100px and up) - col-lg-* */
    #banner { 
		background-position: 50px bottom; 
		h2.h1 { font-size: 2.3em; }
		.details { 
			text-align: left; 
			padding-left: 310px; 
			ul.check {text-align: left; }
			img.guarantee { margin-left: 100px; }
		}
	}
    #points .icon { display: inline-block; margin-right: 10px; vertical-align: middle; }
    #points .text {vertical-align: middle; }
}
@media (min-width: 75em) { /* Extra large devices (large desktops, 1200px and up) - col-xl-* */
	#header #support span.phone { display: inline-block; }
    #banner { background-position: 30px bottom; }
}
@import 'featherlight';