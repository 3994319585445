/* ----------------------------------------------------------------------------
	Navigation Menu - Mobile first
----------------------------------------------------------------------------- */
#menutoggle {
	display: block;
	margin: 0 15px 0 0;
	padding: 0 22px;
	width: auto;
	color: #fff;
	background: #04325f;
	height: 45px;
	font: 18px/46px 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
	text-align: left;
	text-transform: uppercase;
	cursor: pointer;
	position: absolute;
	right: 0;
	bottom: 0;
	border-radius: 4px 4px 0 0;
	transition: background 0.2s linear 0s;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
#menutoggle span {
	display: none;
}
#menutoggle:before {
	display: inline-block;
	margin-left: 0;
	width: 18px;
	height: 45px;
	line-height: 48px;
	content: "\e826";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: 21px;
	vertical-align: top;
}
#menutoggle.active:before {
	content: "\e844";
}
#menutoggle:hover, #menutoggle:active, #menutoggle.active {
	background-color: $main-color;
	border-radius: 4px 4px 0 0;
}
#menutoggle {
	display: block;
}
#nav_wrap {
    background: $main-color;
}
#nav {
	clear: both;
	margin: 0;
	width: 100%;
	height: 0;
	overflow: visible;
	position: relative;
	z-index: 100;
	border-top: 1px solid #477294;
}
#nav ul {
	float: none;
	display: none;
	margin: 0;
	width: auto;
	/*max-height: 0;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;*/
	background: $main-color;
	overflow: hidden;
	/*transition: all 0.1s ease-in 0s;*/
	border-radius: 0 0 4px 4px;
	top: 1px;
	border: 0;
	position: relative;
}
#nav #menutoggle.active + ul {
	max-height: 1000px;
	/*-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;*/
}
#nav ul li, #nav ul ul.submenu li {
	display: block;
	margin: 0;
	padding: 0;
	height: auto;
	overflow: hidden;
	border: 0;
	border-top: 1px dotted rgba(255, 255, 255, 0.4);
}
#nav ul li:first-child {
	border-top: 0;
}
#nav ul li.onmobile {
	display: block;
}
#nav ul li a, #nav ul li span.submenu, #nav ul li ul.submenu li a {
	float: none;
	display: block;
	margin: 0;
	padding: 9px 20px;
	height: auto;
	color: #fff;
	line-height: 1.6em;
	text-align: left;
	font-size: 15px;
	font-weight: normal;
	font-family: 'Open Sans', 'Helvetica Neue', Tahoma, Arial, sans-serif;
	border: 0;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#nav ul li span.submenu:after {
	float: right;
	margin-left: 10px;
	width: 15px;
	height: inherit;
	content: "\e817";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: 105%;
	vertical-align: top;
}
#nav ul li span.submenu.active:after {
	content: "\e816";
}
#nav ul ul.submenu {
	display: none;
	margin: 0;
	padding: 0;
	width: 100%;
	max-height: 360px;
	background: none;
	opacity: 1;
	position: relative;
	top: 0;
	overflow-y: auto;
}
#nav ul li ul.submenu li a {
	padding-left: 40px;
	background: none;
	border: 0;
}
#nav ul li ul.submenu li a:before {
	display: inline-block;
	margin-right: 5px;
	margin-left: -15px;
	width: 10px;
	height: inherit;
	content: "\00BB";
	color: inherit;
	font-family: 'icons';
	text-align: center;
	font-size: inherit;
	vertical-align: top;
}
#nav li a:hover, #nav li a.active, #nav li:hover span.submenu, #nav ul.submenu li a:hover, #nav ul.submenu li a.active {
	background: #04325f;
}
@media (min-width: 36em) { /* Small devices (landscape phones, 576px and up) */
/*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
	#menutoggle {
		padding: 0 15px 0 15px;
	}
	#menutoggle span {
		display: inline-block;
	}
	#menutoggle:before {
		margin-right: 10px;
	}
}
@media (min-width: 65.625em) { /* Large devices (desktops, 1050px and up) */
	#menutoggle {
		display: none;
	}
	#nav_wrap {
		padding-left: 15px;
		padding-right: 15px;
		border-top: 1px solid $main-color;
        border-bottom: 1px solid $main-color;
        background: $main-color;
	}
	#nav {
		height: 45px;
		background: transparent;
		position: relative;
		z-index: 99;
		border: 0;
		border-radius: 0;
	}
	#nav ul {
		display: block !important;
		float: left;
		width: 100%;
		height: auto;
		background-color: transparent;
		/*max-height: 1000px;
		-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		opacity: 1;*/
		margin: 4px 0;
		border-radius: 0;
		position: relative;
		z-index: 10;
		top: 0;
		overflow: visible;
	}
	#nav ul li {
		float: left;
		margin: 0 5px;
		padding: 0;
		height: 37px;
		width: auto;
		min-width: auto;
		position: relative;
		border: 0;
		overflow: visible;
	}
	#nav ul li:first-child {
		margin-left: 0;
		border-left: 0;
	}
	#nav ul li.last {
		border-right: 0;
	}
	#nav ul li.right {
		float: right;
		margin-right: 4px;
		border-right: 0;
	}
	#nav ul li.onmobile {
		display: none;
	}
	#nav ul li a, #nav ul li span.submenu {
		float: left;
		margin: 0;
		padding: 0 9px;
		height: 37px;
		border: 0;
		color: #fff;
		line-height: 38px;
		text-align: center;
		text-transform: uppercase;
		border-radius: 3px;
		transition: all 0.2s linear 0s;
		cursor: pointer;
	}
    #nav ul li a:hover, #nav ul li a:active, #nav ul li a.active, #nav ul li:hover span.dropdown, #nav ul li span.dropdown.active.hover{
        background: darken($main-color, 10%);
    }
	#nav ul li span.submenu:after {
		display: none;
	}
	#nav li a:hover, #nav li a.active, #nav li:hover span.submenu, #nav ul.submenu li a:hover, #nav ul li span.submenu.active {
		background: #04325f;
        color: #fff;
	}
	#nav ul li:hover a.submenu, #nav ul li:hover span.submenu, #nav ul li span.submenu.active.hover {
		border-radius: 3px 3px 0 0;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	#nav ul li:hover {
		z-index: 11;
	}
	#nav ul li ul.submenu {
		float: left;
		margin: 0;
		padding: 0;
		width: 250px;
		max-height: 0;
		/*max-height: 360px;
		overflow-y: auto;*/
		position: absolute;
		/*left: -999em;  Hides the drop down*/
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: alpha(opacity=0);
		opacity: 0;
		background: #455a64;
		top: 95%;
		z-index: 10;
		border-radius: 0 3px 3px 3px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
		overflow: hidden;
		overflow-y: auto;
	}
	#nav ul li:hover ul.submenu, #nav ul li ul.submenu.active {
		padding: 3px 0;
		left: 0;
		max-height: 360px;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		opacity: 1;
	}
	#nav ul li ul.submenu li {
		float: none;
		display: block;
		margin: 0;
		padding: 0;
		border: 0;
		height: auto;
	}
	#nav ul li ul.submenu li a {
		float: none;
		display: block;
		margin: 0;
		padding: 7px 15px 7px 28px;
		height: auto;
		text-transform: none;
		text-align: left;
		line-height: 1.5em;
		border-radius: 0;
		border: 0;
		border-top: 1px dotted #37474f;
	}
	#nav ul li ul.submenu li:first-child a {
		border: 0;
	}
	#nav ul li ul.submenu li a:hover, #nav ul li ul.submenu li a.active {
		background: #37474f;
	}
}
