/* ----------------------------------------------------------------------------
	Containers & Grid
----------------------------------------------------------------------------- */
.wrap { width: 100%; }
.container, .row {
	display: block;
	margin: 0 auto;
	/*padding-left: 15px;
	padding-right: 15px;*/
	/*width: 94%;*/
	width: 100%;
	max-width: 1200px; /* 1170px content area */
	/*max-width: 540px;*/
	/*transition: all 0.3s ease 0s;*/
}
.container.boxed {
	width: 100%;
	max-width: 1230px;
	/* 1170px content area */
	background: #ffffff;
	/* just here for demo */
}
.row {
	clear: both;
	width: auto;
	max-width: 1200px;
	/*margin-left: -15px;
	margin-right: -15px;*/
}
.container.boxed > .row {
	max-width: none;
	/*margin-left: -15px;
	margin-right: -15px;*/
}
.container.fluid {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	max-width: 1200px; /* 1170px content area */
	/*padding-left: 15px;
	padding-right: 15px;*/
	/*transition: all 0.3s ease 0s;*/
}
.col-xs-1 .row, .col-xs-2 .row, .col-xs-3 .row, .col-xs-4 .row, .col-xs-5 .row, .col-xs-6 .row, .col-xs-7 .row, .col-xs-8 .row, .col-xs-9 .row, .col-xs-10 .row, .col-xs-11 .row, .col-xs-12 .row, .col-sm-1 .row, .col-sm-2 .row, .col-sm-3 .row, .col-sm-4 .row, .col-sm-5 .row, .col-sm-6 .row, .col-sm-7 .row, .col-sm-8 .row, .col-sm-9 .row, .col-sm-10 .row, .col-sm-11 .row, .col-sm-12 .row, .col-md-1 .row, .col-md-2 .row, .col-md-3 .row, .col-md-4 .row, .col-md-5 .row, .col-md-6 .row, .col-md-7 .row, .col-md-8 .row, .col-md-9 .row, .col-md-10 .row, .col-md-11 .row, .col-md-12 .row, .col-lg-1 .row, .col-lg-2 .row, .col-lg-3 .row, .col-lg-4 .row, .col-lg-5 .row, .col-lg-6 .row, .col-lg-7 .row, .col-lg-8 .row, .col-lg-9 .row, .col-lg-10 .row, .col-lg-11 .row, .col-lg-12 .row, .col-xl-1 .row, .col-xl-2 .row, .col-xl-3 .row, .col-xl-4 .row, .col-xl-5 .row, .col-xl-6 .row, .col-xl-7 .row, .col-xl-8 .row, .col-xl-9 .row, .col-xl-10 .row, .col-xl-11 .row, .col-xl-12 .row {
	width: auto !important;
	margin-top: 0;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 0;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
    position: relative;
    min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
    width: 100%;
    /*transition: all 0.3s ease 0s;*/
}
.centered, .center_block {
	clear: both;
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.container:after, .row:after, .col-xs-1:after, .col-xs-2:after, .col-xs-3:after, .col-xs-4:after, .col-xs-5:after, .col-xs-6:after, .col-xs-7:after, .col-xs-8:after, .col-xs-9:after, .col-xs-10:after, .col-xs-11:after, .col-xs-12:after, .col-sm-1:after, .col-sm-2:after, .col-sm-3:after, .col-sm-4:after, .col-sm-5:after, .col-sm-6:after, .col-sm-7:after, .col-sm-8:after, .col-sm-9:after, .col-sm-10:after, .col-sm-11:after, .col-sm-12:after, .col-md-1:after, .col-md-2:after, .col-md-3:after, .col-md-4:after, .col-md-5:after, .col-md-6:after, .col-md-7:after, .col-md-8:after, .col-md-9:after, .col-md-10:after, .col-md-11:after, .col-md-12:after, .col-lg-1:after, .col-lg-2:after, .col-lg-3:after, .col-lg-4:after, .col-lg-5:after, .col-lg-6:after, .col-lg-7:after, .col-lg-8:after, .col-lg-9:after, .col-lg-10:after, .col-lg-11:after, .col-lg-12:after, .col-xl-1:after, .col-xl-2:after, .col-xl-3:after, .col-xl-4:after, .col-xl-5:after, .col-xl-6:after, .col-xl-7:after, .col-xl-8:after, .col-xl-9:after, .col-xl-10:after, .col-xl-11:after, .col-xl-12:after {
	content: "";
    display: table;
    clear: both;
}

.hide, .hidden { display: none !important; }
.show { display: block !important; }
.invisible { visibility: hidden; }
.text_hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }
.affix { position: fixed; }
.float_left { float: left; }
.float_right { float: right; }
.center { text-align: center; }
.clear { clear: both; }
.clear_left { clear: left; }
.clear_right { clear: right; }

.col-xs-1 { width: 8.33333333% }
.col-xs-2 { width: 16.66666667% }
.col-xs-3 { width: 25% }
.col-xs-4 { width: 33.33333333% }
.col-xs-5 { width: 41.66666667% }
.col-xs-6 { width: 50% }
.col-xs-7 { width: 58.33333333% }
.col-xs-8 { width: 66.66666667% }
.col-xs-9 { width: 75% }
.col-xs-10 { width: 83.33333333% }
.col-xs-11 { width: 91.66666667% }
.col-xs-12 { width: 100% }
.offset-xs-0 { margin-left: 0% }
.offset-xs-1 { margin-left: 8.33333333% }
.offset-xs-2 { margin-left: 16.66666667% }
.offset-xs-3 { margin-left: 25% }
.offset-xs-4 { margin-left: 33.33333333% }
.offset-xs-5 { margin-left: 41.66666667% }
.offset-xs-6 { margin-left: 50% }
.offset-xs-7 { margin-left: 58.33333333% }
.offset-xs-8 { margin-left: 66.66666667% }
.offset-xs-9 { margin-left: 75% }
.offset-xs-10 { margin-left: 83.33333333% }
.offset-xs-11 { margin-left: 91.66666667% }
.offset-xs-12 { margin-left: 100% }
.pull-xs-0 { right: auto }
.pull-xs-1 { right: 8.33333333% }
.pull-xs-2 { right: 16.66666667% }
.pull-xs-3 { right: 25% }
.pull-xs-4 { right: 33.33333333% }
.pull-xs-5 { right: 41.66666667% }
.pull-xs-6 { right: 50% }
.pull-xs-7 { right: 58.33333333% }
.pull-xs-8 { right: 66.66666667% }
.pull-xs-9 { right: 75% }
.pull-xs-10 { right: 83.33333333% }
.pull-xs-11 { right: 91.66666667% }
.pull-xs-12 { right: 100% }
.push-xs-0 { left: auto }
.push-xs-1 { left: 8.33333333% }
.push-xs-2 { left: 16.66666667% }
.push-xs-3 { left: 25% }
.push-xs-4 { left: 33.33333333% }
.push-xs-5 { left: 41.66666667% }
.push-xs-6 { left: 50% }
.push-xs-7 { left: 58.33333333% }
.push-xs-8 { left: 66.66666667% }
.push-xs-9 { left: 75% }
.push-xs-10 { left: 83.33333333% }
.push-xs-11 { left: 91.66666667% }
.push-xs-12 { left: 100% }
.centered-xs {
	float: none;
	clear: both;
	margin-right: auto;
	margin-left: auto;
}
.uncentered-xs {
	float: left;
	clear: none;
	margin-right: 0;
	margin-left: 0;
}

/*@media (min-width: 36em) { /* Small devices (landscape phones, 576px and up) */
@media (min-width: 34.375em) { /* Small devices (landscape phones, 549px and up) */
	.container { width: 94%; max-width: 570px; max-width: 600px; }
	.container.fluid { max-width: 94%; }
	.container.boxed { padding-left: 15px; padding-right: 15px; }
	.container.boxed > .row { 	margin-left: -15px; margin-right: -15px; }
    .col-sm-1 { width: 8.33333333% }
    .col-sm-2 { width: 16.66666667% }
    .col-sm-3 { width: 25% }
    .col-sm-4 { width: 33.33333333% }
    .col-sm-5 { width: 41.66666667% }
    .col-sm-6 { width: 50% }
    .col-sm-7 { width: 58.33333333% }
    .col-sm-8 { width: 66.66666667% }
    .col-sm-9 { width: 75% }
    .col-sm-10 { width: 83.33333333% }
    .col-sm-11 { width: 91.66666667% }
    .col-sm-12 { width: 100% }
    .offset-sm-0 { margin-left: 0% }
    .offset-sm-1 { margin-left: 8.33333333% }
    .offset-sm-2 { margin-left: 16.66666667% }
    .offset-sm-3 { margin-left: 25% }
    .offset-sm-4 { margin-left: 33.33333333% }
    .offset-sm-5 { margin-left: 41.66666667% }
    .offset-sm-6 { margin-left: 50% }
    .offset-sm-7 { margin-left: 58.33333333% }
    .offset-sm-8 { margin-left: 66.66666667% }
    .offset-sm-9 { margin-left: 75% }
    .offset-sm-10 { margin-left: 83.33333333% }
    .offset-sm-11 { margin-left: 91.66666667% }
    .offset-sm-12 { margin-left: 100% }
    .pull-sm-0 { right: auto }
    .pull-sm-1 { right: 8.33333333% }
    .pull-sm-2 { right: 16.66666667% }
    .pull-sm-3 { right: 25% }
    .pull-sm-4 { right: 33.33333333% }
    .pull-sm-5 { right: 41.66666667% }
    .pull-sm-6 { right: 50% }
    .pull-sm-7 { right: 58.33333333% }
    .pull-sm-8 { right: 66.66666667% }
    .pull-sm-9 { right: 75% }
    .pull-sm-10 { right: 83.33333333% }
    .pull-sm-11 { right: 91.66666667% }
    .pull-sm-12 { right: 100% }
    .push-sm-0 { left: auto }
    .push-sm-1 { left: 8.33333333% }
    .push-sm-2 { left: 16.66666667% }
    .push-sm-3 { left: 25% }
    .push-sm-4 { left: 33.33333333% }
    .push-sm-5 { left: 41.66666667% }
    .push-sm-6 { left: 50% }
    .push-sm-7 { left: 58.33333333% }
    .push-sm-8 { left: 66.66666667% }
    .push-sm-9 { left: 75% }
    .push-sm-10 { left: 83.33333333% }
    .push-sm-11 { left: 91.66666667% }
    .push-sm-12 { left: 100% }
	.centered-sm {
		float: none;
		clear: both;
		margin-right: auto;
		margin-left: auto;
	}
	.uncentered-sm {
		float: left;
		clear: none;
		margin-right: 0;
		margin-left: 0;
	}
}
@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
	.container { width: 94%; max-width: 750px; max-width: 800px }
	.container.fluid { max-width: 94%; }
    .col-md-1 { width: 8.33333333% }
    .col-md-2 { width: 16.66666667% }
    .col-md-3 { width: 25% }
    .col-md-4 { width: 33.33333333% }
    .col-md-5 { width: 41.66666667% }
    .col-md-6 { width: 50% }
    .col-md-7 { width: 58.33333333% }
    .col-md-8 { width: 66.66666667% }
    .col-md-9 { width: 75% }
    .col-md-10 { width: 83.33333333% }
    .col-md-11 { width: 91.66666667% }
    .col-md-12 { width: 100% }
    .offset-md-0 { margin-left: 0% }
    .offset-md-1 { margin-left: 8.33333333% }
    .offset-md-2 { margin-left: 16.66666667% }
    .offset-md-3 { margin-left: 25% }
    .offset-md-4 { margin-left: 33.33333333% }
    .offset-md-5 { margin-left: 41.66666667% }
    .offset-md-6 { margin-left: 50% }
    .offset-md-7 { margin-left: 58.33333333% }
    .offset-md-8 { margin-left: 66.66666667% }
    .offset-md-9 { margin-left: 75% }
    .offset-md-10 { margin-left: 83.33333333% }
    .offset-md-11 { margin-left: 91.66666667% }
    .offset-md-12 { margin-left: 100% }
    .pull-md-0 { right: auto }
    .pull-md-1 { right: 8.33333333% }
    .pull-md-2 { right: 16.66666667% }
    .pull-md-3 { right: 25% }
    .pull-md-4 { right: 33.33333333% }
    .pull-md-5 { right: 41.66666667% }
    .pull-md-6 { right: 50% }
    .pull-md-7 { right: 58.33333333% }
    .pull-md-8 { right: 66.66666667% }
    .pull-md-9 { right: 75% }
    .pull-md-10 { right: 83.33333333% }
    .pull-md-11 { right: 91.66666667% }
    .pull-md-12 { right: 100% }
    .push-md-0 { left: auto }
    .push-md-1 { left: 8.33333333% }
    .push-md-2 { left: 16.66666667% }
    .push-md-3 { left: 25% }
    .push-md-4 { left: 33.33333333% }
    .push-md-5 { left: 41.66666667% }
    .push-md-6 { left: 50% }
    .push-md-7 { left: 58.33333333% }
    .push-md-8 { left: 66.66666667% }
    .push-md-9 { left: 75% }
    .push-md-10 { left: 83.33333333% }
    .push-md-11 { left: 91.66666667% }
    .push-md-12 { left: 100% }
	.centered-md {
		float: none;
		clear: both;
		margin-right: auto;
		margin-left: auto;
	}
	.uncentered-md {
		float: left;
		clear: none;
		margin-right: 0;
		margin-left: 0;
	}
}
@media (min-width: 62em) { /* Large devices (desktops, 992px and up) */
	.container { width: 96%; max-width: 1200px; }
	.container.fluid { max-width: 96%; }
    .col-lg-1 { width: 8.33333333% }
    .col-lg-2 { width: 16.66666667% }
    .col-lg-3 { width: 25% }
    .col-lg-4 { width: 33.33333333% }
    .col-lg-5 { width: 41.66666667% }
    .col-lg-6 { width: 50% }
    .col-lg-7 { width: 58.33333333% }
    .col-lg-8 { width: 66.66666667% }
    .col-lg-9 { width: 75% }
    .col-lg-10 { width: 83.33333333% }
    .col-lg-11 { width: 91.66666667% }
    .col-lg-12 { width: 100% }
    .offset-lg-0 { margin-left: 0% }
    .offset-lg-1 { margin-left: 8.33333333% }
    .offset-lg-2 { margin-left: 16.66666667% }
    .offset-lg-3 { margin-left: 25% }
    .offset-lg-4 { margin-left: 33.33333333% }
    .offset-lg-5 { margin-left: 41.66666667% }
    .offset-lg-6 { margin-left: 50% }
    .offset-lg-7 { margin-left: 58.33333333% }
    .offset-lg-8 { margin-left: 66.66666667% }
    .offset-lg-9 { margin-left: 75% }
    .offset-lg-10 { margin-left: 83.33333333% }
    .offset-lg-11 { margin-left: 91.66666667% }
    .offset-lg-12 { margin-left: 100% }
    .pull-lg-0 { right: auto }
    .pull-lg-1 { right: 8.33333333% }
    .pull-lg-2 { right: 16.66666667% }
    .pull-lg-3 { right: 25% }
    .pull-lg-4 { right: 33.33333333% }
    .pull-lg-5 { right: 41.66666667% }
    .pull-lg-6 { right: 50% }
    .pull-lg-7 { right: 58.33333333% }
    .pull-lg-8 { right: 66.66666667% }
    .pull-lg-9 { right: 75% }
    .pull-lg-10 { right: 83.33333333% }
    .pull-lg-11 { right: 91.66666667% }
    .pull-lg-12 { right: 100% }
    .push-lg-0 { left: auto }
    .push-lg-1 { left: 8.33333333% }
    .push-lg-2 { left: 16.66666667% }
    .push-lg-3 { left: 25% }
    .push-lg-4 { left: 33.33333333% }
    .push-lg-5 { left: 41.66666667% }
    .push-lg-6 { left: 50% }
    .push-lg-7 { left: 58.33333333% }
    .push-lg-8 { left: 66.66666667% }
    .push-lg-9 { left: 75% }
    .push-lg-10 { left: 83.33333333% }
    .push-lg-11 { left: 91.66666667% }
    .push-lg-12 { left: 100% }
	.centered-lg {
		float: none;
		clear: both;
		margin-right: auto;
		margin-left: auto;
	}
	.uncentered-lg {
		float: left;
		clear: none;
		margin-right: 0;
		margin-left: 0;
	}
}
@media (min-width: 75em) { /* Extra large devices (large desktops, 1200px and up) */
	.container.fluid { width: 96%; max-width: 1200px; }
    .col-xl-1 { width: 8.33333333% }
    .col-xl-2 { width: 16.66666667% }
    .col-xl-3 { width: 25% }
    .col-xl-4 { width: 33.33333333% }
    .col-xl-5 { width: 41.66666667% }
    .col-xl-6 { width: 50% }
    .col-xl-7 { width: 58.33333333% }
    .col-xl-8 { width: 66.66666667% }
    .col-xl-9 { width: 75% }
    .col-xl-10 { width: 83.33333333% }
    .col-xl-11 { width: 91.66666667% }
    .col-xl-12 { width: 100% }
    .offset-xl-0 { margin-left: 0% }
    .offset-xl-1 { margin-left: 8.33333333% }
    .offset-xl-2 { margin-left: 16.66666667% }
    .offset-xl-3 { margin-left: 25% }
    .offset-xl-4 { margin-left: 33.33333333% }
    .offset-xl-5 { margin-left: 41.66666667% }
    .offset-xl-6 { margin-left: 50% }
    .offset-xl-7 { margin-left: 58.33333333% }
    .offset-xl-8 { margin-left: 66.66666667% }
    .offset-xl-9 { margin-left: 75% }
    .offset-xl-10 { margin-left: 83.33333333% }
    .offset-xl-11 { margin-left: 91.66666667% }
    .offset-xl-12 { margin-left: 100% }
    .pull-xl-0 { right: auto }
    .pull-xl-1 { right: 8.33333333% }
    .pull-xl-2 { right: 16.66666667% }
    .pull-xl-3 { right: 25% }
    .pull-xl-4 { right: 33.33333333% }
    .pull-xl-5 { right: 41.66666667% }
    .pull-xl-6 { right: 50% }
    .pull-xl-7 { right: 58.33333333% }
    .pull-xl-8 { right: 66.66666667% }
    .pull-xl-9 { right: 75% }
    .pull-xl-10 { right: 83.33333333% }
    .pull-xl-11 { right: 91.66666667% }
    .pull-xl-12 { right: 100% }
    .push-xl-0 { left: auto }
    .push-xl-1 { left: 8.33333333% }
    .push-xl-2 { left: 16.66666667% }
    .push-xl-3 { left: 25% }
    .push-xl-4 { left: 33.33333333% }
    .push-xl-5 { left: 41.66666667% }
    .push-xl-6 { left: 50% }
    .push-xl-7 { left: 58.33333333% }
    .push-xl-8 { left: 66.66666667% }
    .push-xl-9 { left: 75% }
    .push-xl-10 { left: 83.33333333% }
    .push-xl-11 { left: 91.66666667% }
    .push-xl-12 { left: 100% }
	.centered-xl {
		float: none;
		clear: both;
		margin-right: auto;
		margin-left: auto;
	}
	.uncentered-xl {
		float: left;
		clear: none;
		margin-right: 0;
		margin-left: 0;
	}
}

/* --- Responsive Video --- */
.video_wrap { margin-bottom: 15px; padding-bottom: 56.25%; width: 100%; position: relative; height: 0; }
.video_wrap > video, .video_wrap > iframe, .video_wrap > object, .video_wrap > embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
